import { DateUtils } from "../../../../classes/date.utils";
import { onNextFrame } from "../../../../classes/render-util";
export class InputController {
    constructor(config) {
        this.config = config;
        this._inputElement = null;
        this.lastValue = "";
        this.valueBeforeFocus = "";
        this.focusHandle = () => this.onFocus();
        this.blurHandle = () => this.onBlur();
        this.inputHandle = (e) => this.onInput(e);
    }
    get inputElement() {
        return this._inputElement;
    }
    focus(focusOptions) {
        var _a, _b;
        this.lastValue = (_a = this._inputElement) === null || _a === void 0 ? void 0 : _a.value;
        this.valueBeforeFocus = this.lastValue;
        (_b = this._inputElement) === null || _b === void 0 ? void 0 : _b.focus(focusOptions);
    }
    setValue(value, forceUpdate = false) {
        this.internalSetValue(value, forceUpdate);
    }
    sendInput(input) {
        var _a;
        // Does not set lastValue because input event is dispatched
        this.setInputElementValue(input);
        const evt = new Event("input", {
            bubbles: true,
            cancelable: true,
        });
        evt["data"] = input;
        evt["insertType"] = "insertText";
        (_a = this._inputElement) === null || _a === void 0 ? void 0 : _a.dispatchEvent(evt);
    }
    preventInput(input, inputType) {
        if (/delete|history/.test(inputType)) {
            return false;
        }
        // "." doesn't do anything before the user didn't make his first input
        if (input === ".") {
            if (this.lastValue !== this.valueBeforeFocus) {
                this.finish();
            }
            return true;
        }
        const inputNumber = Number(input);
        if (isNaN(inputNumber)) {
            return true;
        }
    }
    onInput(e) {
        e.preventDefault();
        const currentValue = this._inputElement.value;
        if (this.preventInput(e.data, e.inputType)) {
            // In case there was no input we want to keep the previous selection
            if (this.lastValue === this.valueBeforeFocus) {
                requestAnimationFrame(() => {
                    this._inputElement.select();
                });
            }
            this.updateValue(this.lastValue);
            return;
        }
        const newValue = currentValue.substring(0, this.maxCharacters);
        this.updateValue(newValue);
        if (newValue.length === this.maxCharacters) {
            this.finish();
        }
    }
    get maxCharacters() {
        return this.config.maxCharacters;
    }
    get value() {
        var _a;
        return parseFloat((_a = this._inputElement) === null || _a === void 0 ? void 0 : _a.value);
    }
    get minValue() {
        return this.config.minValue;
    }
    get maxValue() {
        return this.config.maxValue;
    }
    get required() {
        return this.config.required;
    }
    set required(required) {
        this.config.required = required;
    }
    get disabled() {
        return this.config.disabled;
    }
    set disabled(disabled) {
        this.config.disabled = disabled;
    }
    get defaultStartValue() {
        return this.config.defaultStartValue;
    }
    get formatter() {
        var _a;
        return (_a = this.config.formatter) !== null && _a !== void 0 ? _a : ((value) => value);
    }
    get formattedValue() {
        var _a, _b;
        return (_b = this.formatter((_a = this._inputElement) === null || _a === void 0 ? void 0 : _a.value)) !== null && _b !== void 0 ? _b : "";
    }
    getDisabled() {
        return this.disabled;
    }
    setDisabled(disabled) {
        var _a;
        if (this.disabled !== disabled) {
            this.disabled = disabled;
            (_a = this.onFormStateChanged) === null || _a === void 0 ? void 0 : _a.call(this, {
                disabled: this.disabled,
                required: this.required,
            });
        }
    }
    getRequired() {
        return this.required;
    }
    setRequired(required) {
        var _a;
        if (this.required !== required) {
            this.required = required;
            (_a = this.onFormStateChanged) === null || _a === void 0 ? void 0 : _a.call(this, {
                disabled: this.disabled,
                required: this.required,
            });
        }
    }
    getMinValue() {
        return this.minValue;
    }
    getMaxValue() {
        return this.maxValue;
    }
    isEmpty() {
        var _a, _b, _c;
        return this.disabled || ((_c = (_b = (_a = this._inputElement) === null || _a === void 0 ? void 0 : _a.value) === null || _b === void 0 ? void 0 : _b.length) !== null && _c !== void 0 ? _c : 0) === 0;
    }
    clearInput() {
        if (!this.disabled && this._inputElement != null) {
            this.updateValue(null);
        }
    }
    onFocus() {
        var _a;
        if (this._inputElement.value.length > 0) {
            this.focus();
            this._inputElement.select();
        }
        (_a = this.onInputFocused) === null || _a === void 0 ? void 0 : _a.call(this);
    }
    onBlur() {
        var _a;
        this.valueBeforeFocus = this.lastValue;
        this.format();
        (_a = this.onInputBlurred) === null || _a === void 0 ? void 0 : _a.call(this);
    }
    setInputElement(el) {
        var _a, _b, _c, _d, _e, _f;
        if (this._inputElement !== el) {
            (_a = this._inputElement) === null || _a === void 0 ? void 0 : _a.removeEventListener("focus", this.focusHandle);
            (_b = this._inputElement) === null || _b === void 0 ? void 0 : _b.removeEventListener("blur", this.blurHandle);
            (_c = this._inputElement) === null || _c === void 0 ? void 0 : _c.removeEventListener("input", this.inputHandle);
            this._inputElement = el;
            (_d = this._inputElement) === null || _d === void 0 ? void 0 : _d.addEventListener("focus", this.focusHandle);
            (_e = this._inputElement) === null || _e === void 0 ? void 0 : _e.addEventListener("blur", this.blurHandle);
            (_f = this._inputElement) === null || _f === void 0 ? void 0 : _f.addEventListener("input", this.inputHandle);
            if (this._inputElement) {
                this._inputElement["i_controller"] = this;
            }
            onNextFrame(() => {
                this.format();
            });
        }
    }
    incrementValue() {
        let currentValue = this._inputElement.value;
        if (currentValue === "" && this.defaultStartValue != null) {
            this.internalSetValue(this.defaultStartValue);
        }
        else {
            const newValue = currentValue === ""
                ? this.minValue
                : Math.min(this.maxValue, parseInt(this._inputElement.value)) + 1;
            this.internalSetValue(Math.max(this.minValue, newValue % (this.maxValue + 1)));
        }
        requestAnimationFrame(() => {
            this._inputElement.select();
        });
    }
    decrementValue() {
        const currentValue = this._inputElement.value;
        if (currentValue === "" && this.defaultStartValue != null) {
            this.internalSetValue(this.defaultStartValue);
        }
        else {
            const newValue = currentValue === ""
                ? this.maxValue
                : Math.min(this.maxValue, parseInt(this._inputElement.value) - 1);
            const lessThanMin = newValue - this.minValue < 0;
            const newComputedValue = lessThanMin
                ? this.maxValue + (newValue - this.minValue) + 1
                : newValue % (this.maxValue + 1);
            this.internalSetValue(newComputedValue);
        }
        requestAnimationFrame(() => {
            this._inputElement.select();
        });
    }
    setInputElementValue(value) {
        if (this._inputElement != null) {
            this._inputElement.value = value;
        }
    }
    internalSetValue(value, emitValueChange = true) {
        const valueAsNumber = parseFloat(value === null || value === void 0 ? void 0 : value.toString());
        if (value != null && (valueAsNumber < this.minValue || valueAsNumber > this.maxValue)) {
            console.warn(`This segment can only be specified with a number between ${this.minValue} and ${this.maxValue} or null to empty the segment.`, this.inputElement);
            return;
        }
        if (this.disabled) {
            value = DateUtils.withMultipleLeadingZeros(this.minValue, 4);
        }
        this.updateValue(value == null || isNaN(+value) ? "" : value.toString(), emitValueChange);
        this.format(emitValueChange);
    }
    updateValue(value, emitValueChange = true) {
        var _a;
        const sameValue = this.lastValue === value;
        this.setInputElementValue(value);
        this.lastValue = value;
        if (!sameValue && emitValueChange) {
            (_a = this.onValueChanged) === null || _a === void 0 ? void 0 : _a.call(this, value);
        }
    }
    finish() {
        var _a;
        (_a = this.onFinished) === null || _a === void 0 ? void 0 : _a.call(this);
        this.format();
    }
    format(emitValueChange = true) {
        var _a, _b;
        const currentValue = parseInt((_a = this._inputElement) === null || _a === void 0 ? void 0 : _a.value);
        if (isNaN(currentValue)) {
            this.updateValue("", emitValueChange);
            return;
        }
        this.updateValue(this.formatter((_b = this._inputElement) === null || _b === void 0 ? void 0 : _b.value), emitValueChange);
    }
}
export function getDayInputController(required, disabled) {
    return new InputController({
        minValue: 1,
        maxValue: 31,
        required,
        disabled,
        maxCharacters: 2,
        formatter: formatWithLeadingZero
    });
}
export function getMonthInputController(required, disabled) {
    return new InputController({
        minValue: 1,
        maxValue: 12,
        required,
        disabled,
        maxCharacters: 2,
        formatter: formatWithLeadingZero
    });
}
export function getYearInputController(required, disabled) {
    return new InputController({
        minValue: 0,
        maxValue: 9999,
        required,
        disabled,
        maxCharacters: 4,
        defaultStartValue: new Date().getFullYear()
    });
}
export function getHourInputController(required, disabled) {
    return new InputController({
        minValue: 0,
        maxValue: 23,
        required,
        disabled,
        maxCharacters: 2,
        formatter: formatWithLeadingZero,
    });
}
export function getMinuteInputController(required, disabled) {
    return new InputController({
        minValue: 0,
        maxValue: 59,
        required,
        disabled,
        maxCharacters: 2,
        formatter: formatWithLeadingZero,
    });
}
export function formatWithLeadingZero(value) {
    if (!value) {
        return value;
    }
    return DateUtils.withLeadingZero(parseInt(value));
}
export function extractDateValuesFromInputControllers(yearInputController, monthInputController, dayInputController) {
    return extractValuesFromInputControllers([yearInputController, monthInputController, dayInputController], (values) => formatToDateString(values.join("-")));
}
export function extractTimeValuesFromInputControllers(hourInputController, minuteInputController) {
    return extractValuesFromInputControllers([hourInputController, minuteInputController], (values) => formatToTimeString(values.join(":")));
}
export function extractValuesFromInputControllers(inputControllers, mergeFormatter) {
    if (inputControllers.every((inputController) => inputController.isEmpty())) {
        return null;
    }
    function getValue(inputController) {
        const defaultValue = inputController.formatter(inputController.getMinValue().toString());
        if (inputController.getDisabled()) {
            return defaultValue;
        }
        return inputController.formattedValue;
    }
    const values = inputControllers.map((inputController) => getValue(inputController));
    // We have to check every input because 'YYYY-MM-' is a valid date for chrome. Firefox is invalid in this case.
    return mergeFormatter(values);
}
export function formatToDateString(value) {
    if (value == null) {
        return null;
    }
    const yearString = value.split("-")[0];
    if (yearString.length !== 4) {
        return null;
    }
    const date = new Date(value);
    if (isNaN(date.getTime())) {
        return null;
    }
    else {
        // Chrome translates "2021-31-02 into 02.03.2021 instead of returning an "Invalid Date"
        const splittedDate = value.split("-").map((val) => parseFloat(val));
        if ((splittedDate[2] != null && splittedDate[2] !== date.getDate()) ||
            (splittedDate[1] != null && splittedDate[1] !== date.getMonth() + 1) ||
            (splittedDate[0] != null && splittedDate[0] !== date.getFullYear())) {
            return null;
        }
        return DateUtils.dateToString(date);
    }
}
export function formatToTimeString(value) {
    if (value == null) {
        return null;
    }
    const date = new Date(`0000-01-01T${value}`);
    const splittedTime = value.split(":").map((val) => parseFloat(val));
    if ((splittedTime[0] != null && splittedTime[0] !== date.getHours()) ||
        (splittedTime[1] != null && splittedTime[1] !== date.getMinutes())) {
        return null;
    }
    return value;
}
